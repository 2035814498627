import { ApiBaseResponse } from "src/interfaces/api-responses/base";

export enum StatusCodes {
    Created = 201,
    Success = 200,
    Forbidden = 403,
    InternalServerError = 500,
    UnAuthorized = 401,
    BadRequest = 400,
    NotFound = 404
}


export interface SuccessMessage {
    code: string;
    message: string;
}

export interface ErrorResponse {
    errorCode: string;
    errorMessage: string;
    errorData?: string;
}

export interface SuccessResponse extends ApiBaseResponse<null> {
}

const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

export const HttpUrls = {
    LOGIN: `${BASE_URL}/admin/auth`,
    LOGOUT: `${BASE_URL}/admin/logout`,
    ADMIN_LIST: `${BASE_URL}/admin/user/getAll`,
    ADD_ADMIN: `${BASE_URL}/admin/user`,
    GET_ADMIN_USER: `${BASE_URL}/admin/user/{adminId}`,
    ACTIVATE_ADMIN_USER: `${BASE_URL}/admin/user/{adminId}/activate`,
    CHANGE_ADMIN_PASSWORD: `${BASE_URL}/admin/changepassword`,

    ADD_USER: `${BASE_URL}/user`,
    USER_LIST: `${BASE_URL}/user/getAll`,
    GET_USER: `${BASE_URL}/user/{userId}`,
    ACTIVATE_USER: `${BASE_URL}/user/{userId}/activate`,

    ADD_VENDOR: `${BASE_URL}/vendor`,
    VENDOR_LIST: `${BASE_URL}/vendor/getAll`,
    VENDOR_LIST_ALL: `${BASE_URL}/vendor/getAll`,
    GET_VENDOR: `${BASE_URL}/vendor/{vendorId}`,

    ADD_DEVICE: `${BASE_URL}/device`,
    EDIT_DEVICE: `${BASE_URL}/device`,
    DEVICES_LIST_ALL: `${BASE_URL}/device/getAll`,
    GET_DEVICE_CONFIG_BY_ID: `${BASE_URL}/device/{deviceId}/config`,
    DELETE_DEVICE_BY_ID: `${BASE_URL}/device/{deviceId}`,
    UPDATE_ALARM_STATUS: `${BASE_URL}/device/alarm/update`,

    ADD_GROUPS: `${BASE_URL}/device/group/{deviceid}`,

    ADD_FACILITY: `${BASE_URL}/facility`,
    FACILITY_LIST_ALL: `${BASE_URL}/facility/getAll`,
    DEVICES_BY_FACILITY_ID: `${BASE_URL}/facility/{facilityId}/devices`,
    GET_FACILITY: `${BASE_URL}/facility/{facilityId}`,

    ADD_USER_ROLE: `${BASE_URL}/role`,
    EDIT_USER_ROLE: `${BASE_URL}/role`,
    ROLES_LIST_ALL: `${BASE_URL}/role/getAll`,
    GET_USER_ROLE: `${BASE_URL}/role/{roleId}`,

    GET_USER_SETTINGS: `${BASE_URL}/appSettings`,

    GET_DASHBOARD_INFO: `${BASE_URL}/admin/dashboard`,

    ADD_ALARM_SETTING: `${BASE_URL}/alarmSetting`,
    GET_ALARM_SETTING: `${BASE_URL}/alarmSetting/{sensorId}`,
    DELETE_ALARM_SETTING: `${BASE_URL}/alarmSetting/{sensorId}`,

    IMAGE_UPLOAD_TEST: `${BASE_URL}/upload/test`,
    GET_FACILITY_USERS_BY_DEVICE_ID: `${BASE_URL}/facility/devices/{deviceId}/users/{searchKey}`,

    GET_MASTER_TYPES: `${BASE_URL}/types/master/getAll`,

    ADD_MASTER_SENSOR_TYPE: `${BASE_URL}/types`,
    LIST_MASTER_SENSOR_TYPE: `${BASE_URL}/types/getAll`,
    GET_MASTER_SENSOR_TYPE: `${BASE_URL}/types/{sensorTypeId}`,

    ADD_MASTER_SENSOR_UNIT: `${BASE_URL}/units`,
    LIST_MASTER_SENSOR_UNIT: `${BASE_URL}/units/getAll`,
    GET_MASTER_SENSOR_UNIT: `${BASE_URL}/units/{sensorUnitId}`

};



