import { Suspense, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';


import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { RouterLinks } from './utils/routes';
import { MasterTypeProvider } from './content/new-pages/pages/sensor-types/UpdateContext';
import { MasterSensorUnitProvider } from './content/new-pages/pages/sensor-units/SensorUnitUpdateContext';



const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

const Login = Loader(lazy(() => import('src/content/pages/Login')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Admin Users

const AddAdminUser = Loader(lazy(() => import('src/content/new-pages/pages/admin/add-admin')));
const EditAdminUser = Loader(lazy(() => import('src/content/new-pages/pages/admin/edit-admin')));
const ListAdminUser = Loader(lazy(() => import('src/content/new-pages/pages/admin/list-admin')));

// Devices

const AddDevice = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/add-device')))
const EditDevice = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/edit-device')));
const ListDevice = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/list-device')));

// Vendor

const AddVendor = Loader(lazy(() => import('src/content/new-pages/pages/vendor/add-vendor')));
const EditVendor = Loader(lazy(() => import('src/content/new-pages/pages/vendor/edit-vendor')));
const ListVendor = Loader(lazy(() => import('src/content/new-pages/pages/vendor/list-vendor')));

// User Roles

const AddRole = Loader(lazy(() => import('src/content/new-pages/pages/roles/add-role')));
const EditRole = Loader(lazy(() => import('src/content/new-pages/pages/roles/edit-role')));
const ListRole = Loader(lazy(() => import('src/content/new-pages/pages/roles/list-role')));

// Users

const AddUser = Loader(lazy(() => import('src/content/new-pages/pages/user/add-user')));
const EditUser = Loader(lazy(() => import('src/content/new-pages/pages/user/edit-user')));
const ListUser = Loader(lazy(() => import('src/content/new-pages/pages/user/list-user')));

// Groups

const AddGroup = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/groups/add-group')));
const EditGroup = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/groups/edit-group')));
const ListGroup = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/groups/list-group')));

// Users

const AddFacility = Loader(lazy(() => import('src/content/new-pages/pages/facilities/add-facility')));
const EditFacility = Loader(lazy(() => import('src/content/new-pages/pages/facilities/edit-facility')));
const ListFacility = Loader(lazy(() => import('src/content/new-pages/pages/facilities/list-facility')));


// User Types

const AddMasterType = Loader(lazy(() => import('src/content/new-pages/pages/sensor-types/add-types')));
const EditMasterType = Loader(lazy(() => import('src/content/new-pages/pages/sensor-types/edit-types')));
const ListMasterType = Loader(lazy(() => import('src/content/new-pages/pages/sensor-types/list-types')));

// User Units

const AddMasterUnit = Loader(lazy(() => import('src/content/new-pages/pages/sensor-units/add-sensor-unit')));
const EditMasterUnit = Loader(lazy(() => import('src/content/new-pages/pages/sensor-units/edit-sensor-unit')));
const ListMasterUnit = Loader(lazy(() => import('src/content/new-pages/pages/sensor-units/list-sensor-unit')));

// Applications

// const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
// const Transactions = Loader(lazy(() => import('src/content/applications/Transactions')));
const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));

// Components

// const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
// const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
// const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
// const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
// const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
// const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
// const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
// const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));
const OurForm = Loader(lazy(() => import('src/content/pages/Components/OurForm')));
const OurTable = Loader(lazy(() => import('src/content/pages/Components/OurTable')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
// const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
// const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
// const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const AddAlarm = Loader(lazy(() => import('src/content/new-pages/pages/alarms/add-alarm')));
const EditAlarm = Loader(lazy(() => import('src/content/new-pages/pages/alarms/edit-alarm')));

const ListSensor = Loader(lazy(() => import('src/content/new-pages/pages/devices-v2/list-sensor')));



const routes: RouteObject[] = [
  {
    path: RouterLinks.GLOBAL,
    element: <BaseLayout />,
    children: [
      {
        path: RouterLinks.DEFAULT,
        element: (
          <Navigate
            to={RouterLinks.LOGIN}
            replace
          />
        )
      },
      {
        path: RouterLinks.LOGIN,
        element: <Login />
      },
      {
        path: 'dashboard',
        element: (
          <SidebarLayout />
        ),
        children: [
          {
            path: '',
            element: <Crypto />
          }

        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: `${RouterLinks.admin.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/admins/add"
            replace
          />
        )
      },
      {
        path: `${RouterLinks.admin.ADD}`,
        element: <AddAdminUser />
      },
      {
        path: `${RouterLinks.admin.EDIT}`,
        element: <EditAdminUser />
      },
      {
        path: `${RouterLinks.admin.LIST}`,
        element: <ListAdminUser />
      }
    ]
  },
  {
    path: `${RouterLinks.device.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/devices/add"
            replace
          />
        )
      },
      {
        path: `${RouterLinks.device.ADD}`,
        element: <AddDevice />
      },
      {
        path: `${RouterLinks.device.EDIT}`,
        element: <EditDevice />
      },
      {
        path: `${RouterLinks.device.LIST}`,
        element: <ListDevice />
      },
      // {
      //   path: `${RouterLinks.device.SENSOR}`,
      //   element: <ListSensor />
      // },
      {
        path: `${RouterLinks.device.SENSOR}`,
        element: <ListSensor />,
      },
      {
        path: `${RouterLinks.device.SENSOR}/${RouterLinks.device.ALARM_ADD}`,
        element: <AddAlarm />
      },
      {
        path: `${RouterLinks.device.SENSOR}/${RouterLinks.device.ALARM_EDIT}`,
        element: <EditAlarm />
      }
    ]
  },
  {
    path: `${RouterLinks.groups.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={RouterLinks.groups.DEFAULT}
            replace
          />
        )
      },
      {
        path: `${RouterLinks.groups.ADD}`,
        element: <AddGroup />
      },
      {
        path: `${RouterLinks.groups.EDIT}`,
        element: <EditGroup />
      },
      {
        path: `${RouterLinks.groups.LIST}`,
        element: <ListGroup />
      }
    ]
  },
  {
    path: `${RouterLinks.vendor.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={RouterLinks.vendor.DEFAULT}
            replace
          />
        )
      },
      {
        path: `${RouterLinks.vendor.ADD}`,
        element: <AddVendor />
      },
      {
        path: `${RouterLinks.vendor.EDIT}`,
        element: <EditVendor />
      },
      {
        path: `${RouterLinks.vendor.LIST}`,
        element: <ListVendor />
      }
    ]
  },
  {
    path: `${RouterLinks.roles.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={RouterLinks.roles.DEFAULT}
            replace
          />
        )
      },
      {
        path: `${RouterLinks.roles.ADD}`,
        element: <AddRole />
      },
      {
        path: `${RouterLinks.roles.EDIT}`,
        element: <EditRole />
      },
      {
        path: `${RouterLinks.roles.LIST}`,
        element: <ListRole />
      }
    ]
  },
  {
    path: `${RouterLinks.users.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={RouterLinks.users.DEFAULT}
            replace
          />
        )
      },
      {
        path: `${RouterLinks.users.ADD}`,
        element: <AddUser />
      },
      {
        path: `${RouterLinks.users.EDIT}`,
        element: <EditUser />
      },
      {
        path: `${RouterLinks.users.LIST}`,
        element: <ListUser />
      }
    ]
  },
  {
    path: `${RouterLinks.facilities.PATH}`,
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={RouterLinks.facilities.DEFAULT}
            replace
          />
        )
      },
      {
        path: `${RouterLinks.facilities.ADD}`,
        element: <AddFacility />
      },
      {
        path: `${RouterLinks.facilities.EDIT}`,
        element: <EditFacility />
      },
      {
        path: `${RouterLinks.facilities.LIST}`,
        element: <ListFacility />
      }
    ]
  },
  {
    path: `${RouterLinks.sensortypes.PATH}`,
    element: (
      <MasterTypeProvider>
        <SidebarLayout />
      </MasterTypeProvider>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to={RouterLinks.sensortypes.DEFAULT}
            replace
          />

        )
      },
      {
        path: `${RouterLinks.sensortypes.ADD}`,
        element: <AddMasterType />
      },
      {
        path: `${RouterLinks.sensortypes.EDIT}`,
        element: <EditMasterType />
      },
      {
        path: `${RouterLinks.sensortypes.LIST}`,
        element: <ListMasterType />
      }
    ]
  },
  {
    path: 'profile',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="details"
            replace
          />
        )
      },
      {
        path: 'details',
        element: <UserProfile />
      },
      {
        path: 'settings',
        element: <UserSettings />
      },
    ]
  },
  {
    path: 'components',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/components/modals"
            replace
          />
        )
      },
      // {
      //   path: 'buttons',
      //   element: <Buttons />
      // },
      {
        path: 'modals',
        element: <Modals />
      },
      // {
      //   path: 'accordions',
      //   element: <Accordions />
      // },
      // {
      //   path: 'tabs',
      //   element: <Tabs />
      // },
      // {
      //   path: 'badges',
      //   element: <Badges />
      // },
      // {
      //   path: 'tooltips',
      //   element: <Tooltips />
      // },
      // {
      //   path: 'avatars',
      //   element: <Avatars />
      // },
      // {
      //   path: 'cards',
      //   element: <Cards />
      // },
      // {
      //   path: 'forms',
      //   element: <Forms />
      // },
      {
        path: 'ourForm',
        element: <OurForm />
      },
      {
        path: 'ourTable',
        element: <OurTable />
      },
    ]
  },
  {
    path: `${RouterLinks.sensorunits.PATH}`,
    element: (
      <MasterSensorUnitProvider>
        <SidebarLayout />
      </MasterSensorUnitProvider>
    ),
    children: [
      {
        path: '',
        element: (

          <Navigate
            to={RouterLinks.sensorunits.DEFAULT}
            replace
          />

        )
      },
      {
        path: `${RouterLinks.sensorunits.ADD}`,
        element: <AddMasterUnit />
      },
      {
        path: `${RouterLinks.sensorunits.EDIT}`,
        element: <EditMasterUnit />
      },
      {
        path: `${RouterLinks.sensorunits.LIST}`,
        element: <ListMasterUnit />
      }
    ]
  },

];

export { routes };
