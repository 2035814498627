
export const RouterLinks = {
    GLOBAL: '/',
    DEFAULT: '/',
    LOGIN: 'login',
    // DASHBOARD: '/dashboards',
    DASHBOARD: '/dashboard',
    FORGOT_PASSWORD: '/forgot-password',
    NOTFOUND: '/not-found',
    // dashboard: {
    //     PATH: 'dashboards',
    //     INDEX: '/dashboards',
    //     DEFAULT: '/dashboards/crypto',
    //     CRYPTO: 'crypto',
    //     MESSENGER: 'messenger'
    // },
    admin: {
        PATH: 'admins',
        INDEX: '/admins',
        DEFAULT: '/admins/add',
        ADD: 'add',
        EDIT: 'edit/:adminId',
        LIST: 'list'
    },
    device: {
        PATH: 'devices',
        INDEX: '/devices',
        DEFAULT: '/devices/add',
        ADD: 'add',
        // EDIT: 'edit/:deviceId',
        EDIT: 'edit',
        LIST: 'list',
        SENSOR: 'sensor',
        ALARM_ADD: 'alarms/add',
        ALARM_EDIT: 'alarms/edit',
    },
    vendor: {
        PATH: 'vendors',
        INDEX: '/vendors',
        DEFAULT: '/vendors/add',
        ADD: 'add',
        EDIT: 'edit/:vendorId',
        LIST: 'list'
    },
    roles: {
        PATH: 'roles',
        INDEX: '/roles',
        DEFAULT: '/roles/add',
        ADD: 'add',
        EDIT: 'edit/:roleId',
        LIST: 'list'
    },
    users: {
        PATH: 'users',
        INDEX: '/users',
        DEFAULT: '/users/add',
        ADD: 'add',
        EDIT: 'edit/:userId',
        LIST: 'list'
    },
    groups: {
        PATH: 'devices/groups',
        INDEX: '/devices/groups',
        DEFAULT: '/devices/groups/add',
        ADD: 'add',
        EDIT: 'edit/:groupId',
        LIST: 'list'
    },
    facilities: {
        PATH: 'facilities',
        INDEX: '/facilities',
        DEFAULT: '/facilities/add',
        ADD: 'add',
        EDIT: 'edit/:facilityId',
        LIST: 'list'
    },
    sensortypes: {
        PATH: 'sensortypes',
        INDEX: '/sensortypes',
        DEFAULT: '/sensortypes/add',
        ADD: 'add',
        EDIT: 'edit',
        LIST: 'list'
    },
    sensorunits: {
        PATH: 'sensorunits',
        INDEX: '/sensorunits',
        DEFAULT: '/sensorunits/add',
        ADD: 'add',
        EDIT: 'edit',
        LIST: 'list'
    },
}
