// Generated by https://quicktype.io


import { IFacility } from "src/interfaces/api-responses/facilities";


export enum GraphType {
    None = 'none',
    Spline = 'spline',
    Bar = 'bar',
    BarColumn = 'column',
    Line = 'line',
    Rototherm = 'rototherm'
}


export interface DeviceRawConfig {
    id: string;
    token: string;
    dts?: string;
    env: string;
    status: number;
    data: any;
    otherData?: any;
    event?: any;
    ch: any
}

export enum ICategory {
    data = 'data',
    event = 'event'
}

export type IChannel = {
    mappingKey: string;
    channelName: string;
    category: ICategory;
    channelType: string;
    channelUnit: string;
    unit: string; // other

    cummilative: boolean;
    graphType: GraphType;
    enumObj: IEnumData;
    enumsStr: string;
    canDelete: boolean;
}

export type DeviceProps = {
    id: string | null;
    name: string;
    description: string;
    deviceImage: File | null;
    channels: IChannel[];
    isEditMode: boolean;
    facility: IFacility | null;
};

export type IDeviceGroups = {
    grouping: GroupingKeys[]

}

export interface FinalDeviceProps extends DeviceProps, IDeviceGroups {
    facilityId: string;
}


export interface GroupingKeys {
    groupId: string;
    name: string;
    canDelete: boolean;
    isMobileGroup: boolean;
    mappingKeys: GroupingKeysTypes[]
}

export interface GroupingKeysTypes {
    type: string;
    key: string;
    name?: string;
}

export type IEnumData = { [key: string]: string }


export interface SensorEvents {
    key: string;
    enumsObj: IEnumData
}

export const initialDeviceProps: FinalDeviceProps = {
    name: '',
    channels: [],
    description: '',
    deviceImage: null,
    id: '',
    grouping: [],
    facility: null,
    isEditMode: false,
    facilityId: '',
}

export type IDevieRawData = { [key: string]: number | IDevieRawData }
export function flattenObject(oldObject: any) {
    const newObject = {};

    flattenHelper(oldObject, newObject, '');

    return newObject;

    function flattenHelper(currentObject: IDevieRawData, newObject: IDevieRawData, previousKeyName: string | null) {
        for (let key in currentObject) {
            let value = currentObject[key];
            if (value.constructor !== Object) {
                if (previousKeyName == null || previousKeyName === '') {
                    newObject[key] = value;
                } else {
                    if (key == null || key === '') {
                        newObject[previousKeyName] = value;
                    } else {
                        newObject[previousKeyName + '.' + key] = value;
                    }
                }
            } else {
                if (previousKeyName == null || previousKeyName === '') {
                    flattenHelper(value as IDevieRawData, newObject, key);
                } else {
                    flattenHelper(value as IDevieRawData, newObject, previousKeyName + '.' + key);
                }
            }
        }
    }
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line eqeqeq
        // eslint-disable-next-line no-mixed-operators
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

