
import { Backdrop, CircularProgress } from '@mui/material';
// import { useParams } from 'react-router';
// import { useState } from 'react';


interface LoadingProps {
    isOpen: boolean;
    message?: string;
}


const Loading: React.FC<LoadingProps> = ({ isOpen, message }) => {

    // const [open, setOpen] = useState(isOpen);
    return (
        <Backdrop open={isOpen} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2000, color: '#fff' }} >
            <CircularProgress color="inherit" />
            {
                message ? <h3>{message}</h3> : null
            }
        </Backdrop>
    )
}


export default Loading;
