
import React, { createContext, useContext, useReducer } from 'react';
import { ISensorUnits as IApiSensorUnits } from 'src/interfaces/api-responses/master-sensor-unit';

type ContextProps = {
    activeStep: number,
    isUpdate: boolean,
    coreDate?: ISensorUnits | null
    formValues: ISensorUnits
    submitFormData: (data: ISensorUnits) => void
}


type ISensorUnits = {
    sensorUnitsId: string;
    sensorTypeId: string;
    unitName: string;
    active: boolean;
    formulae: string;
    createdOn: Date;
    updatedOn: Date;
    master_sensor_type: any;
}

const initialForm: ISensorUnits = {
    sensorUnitsId: '',
    sensorTypeId: '',
    unitName: '',
    active: true,
    formulae: '',
    createdOn: null,
    updatedOn: null,
    master_sensor_type: null,
}

export const MasterTypeContext = createContext<ContextProps>({
    activeStep: 0,
    isUpdate: false,
    coreDate: null,
    formValues: initialForm,
    submitFormData(data: ISensorUnits) { },
})

interface ProviderProps {
    children: React.ReactNode
}

type MasterTypeState = {
    activeStep: number,
    isUpdate: boolean,
    isRefresh: boolean,
    formValues: ISensorUnits

}

type MasterTypeAction =
    | { type: 'increase' }
    | { type: 'decrease' }
    | { type: 'isUpdate'; values: { update: boolean, data: ISensorUnits | null } }
    | { type: 'open'; values: boolean }
    | { type: 'isRefresh'; values: boolean }
    | { type: 'data'; values: ISensorUnits }

function reducer(state: MasterTypeState, action: MasterTypeAction): MasterTypeState {
    switch (action.type) {
        case 'increase':
            return {
                ...state,
                activeStep: state.activeStep + 1
            }
        case 'decrease':
            return {
                ...state,
                activeStep: state.activeStep - 1
            }
        case 'isUpdate':
            return {
                ...state,
                isUpdate: action.values.update,
                // coreData: action.values.update ? action.values.data : null,
                // formValues: action.values.data
            }
        case 'isRefresh':
            return {
                ...state,
                isRefresh: action.values,
                activeStep: 0
            }
        case 'open':
            return {
                ...state,
                // open: action.values
            }

        case 'data':
            //console.log('Values submitted by user ', action.values)
            return {
                ...state,
                formValues: { ...action.values }

            }


        default:
            return state
    }
}


export function MasterSensorUnitProvider({ children }: ProviderProps) {
    // const [isUpdate, setIsUpdate] = React.useState(0);

    const [{ activeStep, formValues, isUpdate }, dispatch] = useReducer(reducer, {
        activeStep: 0,
        isUpdate: false,
        isRefresh: false,
        formValues: initialForm
    }) as unknown as [state: MasterTypeState, dispatch: React.Dispatch<MasterTypeAction>];

    // Proceed to next step

    const submitFormData = (data: ISensorUnits) => dispatch({ type: 'data', values: data })

    return (
        <MasterTypeContext.Provider value={{
            activeStep, formValues, isUpdate, submitFormData
        }}>
            <div>{children}</div>
        </MasterTypeContext.Provider>
    )
}

export function useMasterUnitsContext(): ContextProps {
    return useContext(MasterTypeContext);
}


export const modifyUnitsFromApiUnits = (data: ISensorUnits): IApiSensorUnits => {
    const obj: IApiSensorUnits = {
        ...data,
        sensorTypeId: Number(data.sensorTypeId),
        sensorUnitsId: Number(data.sensorUnitsId),
    }

    return obj;

}
