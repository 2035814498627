
import React, { createContext, useContext, useReducer } from 'react'
import { FinalDeviceProps, initialDeviceProps } from 'src/content/new-pages/pages/devices-v2/device';
import { DeviceConfigRes } from 'src/interfaces/api-responses/device-config-v2';
import { IFacility } from 'src/interfaces/api-responses/facilities';


const formSection = 'basic';

type ContextProps = {
    activeStep: number,
    isUpdate: boolean,
    open: boolean,
    formValues: FinalDeviceProps,
    isRefresh: boolean,
    facilities: IFacility[],
    coreData?: DeviceConfigRes | null,
    selectedDeviceId: string;
    selectedSensorId: string;
    formSection: 'basic' | 'groups' | 'network',
    // handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => void
    handleNext: () => void,
    handleBack: () => void,
    setFacilities: (data: IFacility[]) => void,
    submitFormData: (data: FinalDeviceProps) => void,
    // setCoreData: (data: FinalDeviceProps) => void,
    handleRecordUpdate: (val: boolean, obj: FinalDeviceProps | null) => void,
    handleRefresh: (val: boolean) => void,
    handleOpen: (val: boolean) => void,
    setSelectedDeviceId: (val: string) => void,
    setSelectedSensorId: (val: string) => void,
    // variant: 'outlined' | 'standard' | 'filled'
    // margin: 'dense' | 'normal' | 'none'
}


export const DeviceContext = createContext<ContextProps>({
    activeStep: 0,
    isUpdate: false,
    coreData: null,
    isRefresh: false,
    open: false,
    formValues: initialDeviceProps,
    facilities: [],
    selectedDeviceId: '',
    selectedSensorId: '',
    // handleChange() { },
    handleNext() { },
    handleBack() { },
    setFacilities(data: IFacility[]) { },
    submitFormData(data: FinalDeviceProps) { },
    handleRecordUpdate(val: boolean, obj: FinalDeviceProps | null) { },
    handleRefresh(val: boolean) { },
    handleOpen(val: boolean) { },
    setSelectedDeviceId(val) { },
    setSelectedSensorId(val) { },
    formSection,
    // variant,
    // margin
})

interface ProviderProps {
    children: React.ReactNode
}

type DeviceState = {
    activeStep: number,
    isUpdate: boolean,
    isRefresh: boolean,
    open: boolean,
    selectedDeviceId: string;
    selectedSensorId: string;
    coreData?: FinalDeviceProps | null,
    formValues: FinalDeviceProps,
    facilities: IFacility[]
}

type DeviceAction =
    | { type: 'increase' }
    | { type: 'decrease' }
    | { type: 'isUpdate'; values: { update: boolean, data: FinalDeviceProps | null } }
    | { type: 'open'; values: boolean }
    | { type: 'facilities'; values: IFacility[] }
    | { type: 'isRefresh'; values: boolean }
    | { type: 'data'; values: FinalDeviceProps }
    | { type: 'deviceId'; values: string }
    | { type: 'sensorId'; values: string }


function reducer(state: DeviceState, action: DeviceAction): DeviceState {
    switch (action.type) {
        case 'increase':
            return {
                ...state,
                activeStep: state.activeStep + 1
            }
        case 'decrease':
            return {
                ...state,
                activeStep: state.activeStep - 1
            }
        case 'isUpdate':
            return {
                ...state,
                isUpdate: action.values.update,
                coreData: action.values.update ? action.values.data : null,
                formValues: action.values.data
            }
        case 'isRefresh':
            return {
                ...state,
                isRefresh: action.values,
                activeStep: 0
            }
        case 'open':
            return {
                ...state,
                open: action.values
            }

        case 'data':
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    ...action.values
                }
            }
        case 'facilities':

            return {
                ...state,
                facilities: [...action.values]
            }
        case 'deviceId':
            return {
                ...state,
                selectedDeviceId: action.values
            }

        case 'sensorId':
            return {
                ...state,
                selectedSensorId: action.values
            }

        default:
            return state
    }
}



export function DeviceContextProvider({ children }: ProviderProps) {
    // const [isUpdate, setIsUpdate] = React.useState(0);

    const [{ activeStep, formValues, isUpdate, isRefresh, open, facilities, selectedDeviceId, selectedSensorId }, dispatch] = useReducer(reducer, {
        activeStep: 0,
        isUpdate: false,
        isRefresh: false,
        open: false,
        facilities: [],
        selectedDeviceId: '',
        selectedSensorId: '',
        formValues: initialDeviceProps
    }) as unknown as [state: DeviceState, dispatch: React.Dispatch<DeviceAction>];

    // Proceed to next step
    const handleNext = () => dispatch({ type: 'increase' })
    // Go back to prev step
    const handleBack = () => dispatch({ type: 'decrease' })

    const setFacilities = (data: IFacility[]) => dispatch({ type: 'facilities', values: data })
    const submitFormData = (data: FinalDeviceProps) => dispatch({ type: 'data', values: data })
    const handleRecordUpdate = (val: boolean, data: FinalDeviceProps | null) => dispatch({ type: 'isUpdate', values: { data: data, update: val } })
    const setSelectedDeviceId = (val: string) => dispatch({ type: 'deviceId', values: val })
    const setSelectedSensorId = (val: string) => dispatch({ type: 'sensorId', values: val })
    const handleRefresh = (val: boolean) => dispatch({ type: 'isRefresh', values: val })
    const handleOpen = (val: boolean) => {
        dispatch({ type: 'open', values: val })
    }

    return (
        <DeviceContext.Provider value={{
            activeStep, formValues, handleNext, handleBack, formSection, isUpdate, isRefresh, handleRefresh,
            open, handleOpen,
            submitFormData, handleRecordUpdate, setFacilities, facilities, selectedDeviceId, setSelectedDeviceId,
            selectedSensorId, setSelectedSensorId
        }}>
            <div>{children}</div>
        </DeviceContext.Provider>
    )
}

export function useDeviceContext(): ContextProps {
    return useContext(DeviceContext);
}

