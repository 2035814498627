import { useCallback } from "react";

export const BASE_URL = "http://usdc.wim-x.com";

export const StorageKeys = {
    PERSISTED_STATE: '_p',
    APP_SETTINGS: '_a',
    TOKEN: '_t'
}


export enum Theme {
    Auto,
    Light,
    Dark
}

export const drawerWidth = 240;


export enum IUserType {
    Default = 0,
    User = 1,
    Admin = 99,
    SuperAdmin = 100
}

export const useYupValidationResolver = (validationSchema) =>
    useCallback(
        async data => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false
                });

                return {
                    values,
                    errors: {}
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message
                            }
                        }),
                        {}
                    )
                };
            }
        },
        [validationSchema]
    );