import { useNavigate, useRoutes } from 'react-router-dom';
import { routes } from './router';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useAuthContext } from './utilities/state';
import { useEffect } from 'react';
import { RouterLinks } from './utils/routes';

const App = () => {
  const navigate = useNavigate();
  const { state } = useAuthContext();

  const content = useRoutes(routes);

  useEffect(() => {

    if (!state.authenticated) {
      navigate(`/${RouterLinks.LOGIN}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <ThemeProvider>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <CssBaseline />
      {content}

      {/* <RouterProvider router={routes} /> */}


      {/* </LocalizationProvider> */}
    </ThemeProvider>
  );
}
export default App;
