import React from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { useAuthContext } from 'src/utilities/state';
import { Theme } from 'src/interfaces/api-responses/app-settings';
import useMediaQuery from '@mui/material/useMediaQuery';

export const ThemeContext = React.createContext(
  (themeName: string): void => { }
);

type Props = {
  children?: React.ReactNode
};

const LightTheme = "PureLightTheme";
const DarkTheme = "NebulaFighterTheme";

const ThemeProviderWrapper: React.FC<Props> = (props) => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { state } = useAuthContext();

  const applyTheme = (localTheme: Theme) => {
    if (Theme[localTheme] !== Theme[Theme.Auto]) {
      return themeCreator(Theme[localTheme] === Theme[Theme.Light] ? LightTheme : DarkTheme);
    } else {
      if (prefersDarkMode) {
        return themeCreator(DarkTheme);
      } else {
        return themeCreator(LightTheme);
      }
    }
  };

  const theme = React.useMemo(
    () =>
      applyTheme(state && state.user && state.user.appSettings && state.user.appSettings.themeType ? state.user.appSettings.themeType : Theme.Auto),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prefersDarkMode, state],
  );


  return (
    <>
      {/* <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider >
      </StylesProvider> */}
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider >
    </>
  );
};

export default ThemeProviderWrapper;
