import App from './App';
import ReactDOM from "react-dom/client";

import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import { AppAuthContextProvider } from './utilities/state';
import { AppContextProvider } from './hooks/useApp';
import { DeviceContextProvider } from './contexts/DeviceContext';
import React from 'react';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <AppAuthContextProvider>
        <DeviceContextProvider>
          <HelmetProvider>
            <SidebarProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>


            </SidebarProvider>
          </HelmetProvider>
        </DeviceContextProvider>
      </AppAuthContextProvider>
    </AppContextProvider>,
  </React.StrictMode>
);

serviceWorker.unregister();


